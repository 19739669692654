import React from 'react';
import propTypes from 'prop-types';

import { graphql } from 'gatsby';
import { BLOCKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import Layout from '../components/layout';
import SEO from '../components/seo';

import pageStyles from './page.module.scss';

const richTextOptions = {
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]: node => {
      const {
        description,
        file: {
          'en-US': { url },
        },
      } = node.data.target.fields;
      return (
        <div className={pageStyles.imageContainer}>
          <div>
            <img
              src={`${url}?fl=progressive&q=80`}
              alt={description['en-US']}
            />
          </div>
        </div>
      );
    },
  },
};

const PagePost = ({ data, location }) => {
  const { title, body, description } = data.contentfulProduct;
  const allContent = body.json;

  return (
    <Layout>
      <div className={pageStyles.container}>
        <SEO
          title={title}
          description={description}
          pathname={location.pathname}
        />
        {documentToReactComponents(allContent, richTextOptions)}
        <hr />
      </div>
    </Layout>
  );
};

PagePost.propTypes = {
  data: propTypes.shape({
    contentfulProduct: propTypes.shape({
      body: propTypes.object,
      title: propTypes.string,
      description: propTypes.string,
    }),
  }),
  location: propTypes.shape({
    pathname: propTypes.string,
  }),
};

PagePost.defaultProps = {
  data: {},
  location: {},
};

export default PagePost;

export const pageQuery = graphql`
  query blogPostQuery($slug: String!) {
    contentfulProduct(slug: { eq: $slug }) {
      body {
        json
      }
      slug
      title
      description
    }
  }
`;
